import React, { useCallback } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import { api } from "../api";
import {
  eventState,
  partiesState,
  scenariosState,
  routesState,
  filterState,
  mapState,
  loadingState,
  airlinesState,
  airportsState,
} from "../atoms";

import { getAllPoints, getBoundingBox } from "./utils";

const Controls = () => {
  const map = useAtomValue(mapState);
  const [event] = useAtom(eventState);
  const parties = useAtomValue(partiesState);
  const scenarios = useAtomValue(scenariosState);
  const [filters, setFilters] = useAtom(filterState);
  const [loading, setLoading] = useAtom(loadingState);

  const [routes, setRoutes] = useAtom(routesState);
  const [airports, setAirports] = useAtom(airportsState);
  const [airlines, setAirlines] = useAtom(airlinesState);

  const centerMap = () => {
    const points = getAllPoints(parties.items, scenarios.items);
    if (points.length) {
      map.fitBounds(getBoundingBox(points), {
        padding: 100,
      });
    }
  };

  const getRoutes = () => {
    setLoading(true);

    const params = new URLSearchParams(filters);
    api
      .get(`/events/${event.id}/routes?${params.toString()}`)
      .then(({ data }) => {
        console.log(data);
        setAirports(data.airports.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}));
        setAirlines(data.airlines.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}));
        setRoutes(data.routes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const setFilter = useCallback((name, value) => setFilters((prev) => ({
    ...prev,
    [name]: value,
  })));

  // const handleTypeChange = (e) => setFilter('airlineType', e.target.value);
  // const handleDMChange = (e) => setFilter('distanceMultiplier', parseFloat(e.target.value));

  return (
    <div className="controls">
      <button onClick={centerMap}>centerMap()</button>

      <fieldset className="filters">
        <legend>filters</legend>

        {/* <div className="filter">
          <label>airline type</label>
          <select name="airlineType" onChange={handleTypeChange}>
            <option value="all">all</option>
            <option value="major">major</option>
          </select>
        </div> */}

        <div className="filter">
          <label htmlFor="noSizeDowngrade">terminate at major airports</label>
          <input
            id="noSizeDowngrade"
            type="checkbox"
            name="noSizeDowngrade"
            checked={filters.noSizeDowngrade}
            onChange={(e) => setFilter('noSizeDowngrade', e.target.checked)}
          />
        </div>

        {/* <div className="filter">
          <label>distance factor</label>
          <small>
            This value sets the maximum allowable length for a route relative to
            the direct distance between two points (A and B). For example, a
            multiplier of 2.0 means routes longer than twice the direct distance
            will be filtered out. a route with a DM of 1 is a direct flight.
          </small>
          <input
            type="number"
            value={filters.distanceMultiplier}
            onChange={handleDMChange}
          />
        </div> */}
      </fieldset>

      {/* <button
        onClick={getRoutes}
        disabled={loading}
      >getRoutes()</button>

      <button
        onClick={loadGraph}
        disabled={loading}
      >loadGraph()</button> */}
    </div>
  );
};

export default Controls;
