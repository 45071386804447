import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
  Navigate,
} from "react-router";

import { Shell } from "./app/components/chrome";
import Event from "./app/pages/event";
import Events from "./app/pages/events";
import Home from "./app/pages/home";
import NewEvent from "./app/pages/newevent";
import { meState } from "./app/atoms";
import { useAtom, useAtomValue } from "jotai";
import { useModalManager } from "./app/utils";

const FourOhThree = () => {
  return <div>403</div>;
};

const ProtectedRoutes = () => {
  const { guest } = useAtomValue(meState);
  const [, showModal] = useModalManager();

  if (guest) {
    // showModal("login");
    return <FourOhThree />;
    // return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
}

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Shell />}>
        <Route index element={<Home />} />

        {/* <Route path="auth">
          <Route path="verify" element={<div>Verify</div>} />
          <Route path="forgot" element={<div>Forgot</div>} />
          <Route path="reset" element={<div>Reset</div>} />
        </Route> */}

        <Route element={<ProtectedRoutes />}>
          <Route path="events">
            <Route index element={<Events />} />
            <Route path=":eventId" element={<Event />} />
            <Route path="new" element={<NewEvent />} />
          </Route>

          <Route path="settings" element={<div>Settings</div>} />
          <Route path="account" element={<div>Account</div>} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

createRoot(document.getElementById("root")).render(<App />);
