import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Button } from "./inputs";

const DoubleTapButton = ({ children, onClick, ...props }) => {
  const [lastTap, setLastTap] = useState(0);
  const [open, setOpen] = useState(false);
  const timeoutSeconds = 1000;

  const handleTap = useCallback(
    (e) => {
      const now = Date.now();

      if (!open) {
        setLastTap(now);
        setOpen(true);
      } else if (now - lastTap < timeoutSeconds) {
        setLastTap(0);
        setOpen(false);

        onClick(e);
      }
    },
    [lastTap, onClick]
  );

  useEffect(() => {
    if (!open) return;

    const timeout = setTimeout(() => {
      setOpen(false);
      setLastTap(0);
    }, timeoutSeconds);

    return () => clearTimeout(timeout);
  }, [open]);

  return (
    <StyledDoubleTapButton>
      <Button {...props} type="button" onClick={handleTap}>
        {children}
      </Button>
      {open && <div className="open">double tap to confirm</div>}
    </StyledDoubleTapButton>
  );
};

const StyledDoubleTapButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .open {
    color: red;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
`;

export default DoubleTapButton;
