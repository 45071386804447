import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import styled from "styled-components";
import { DateTime } from "luxon";

import { api } from "../api.js";

function formatEventDates(event) {
  const { start_at, end_at } = event;

  // Both dates are missing
  if (!start_at && !end_at) {
    return "";
  }

  // Only start date is provided
  if (start_at && !end_at) {
    const start = DateTime.fromISO(start_at);
    return start.toFormat("M/d/yyyy");
  }

  // Only end date is provided
  if (!start_at && end_at) {
    const end = DateTime.fromISO(end_at);
    return end.toFormat("M/d/yyyy");
  }

  // Both dates are provided
  const start = DateTime.fromISO(start_at);
  const end = DateTime.fromISO(end_at);

  if (start.hasSame(end, "day")) {
    // Same day
    return start.toFormat("M/d/yyyy");
  } else if (start.hasSame(end, "month")) {
    // Same month
    return `${start.toFormat("M/d")} - ${end.toFormat("d")}`;
  } else if (start.hasSame(end, "year")) {
    // Same year
    return `${start.toFormat("M/d")} - ${end.toFormat("M/d")}`;
  } else {
    // Different years
    return `${start.toFormat("M/d/yyyy")} - ${end.toFormat("M/d/yyyy")}`;
  }
}

const EventsTable = ({ events }) => {
  return (
    <div className="events">
      <div className="event header">
        <div className="name">Name</div>
        <div className="dates">Dates</div>
      </div>
      {events.items.map((event) => {
        return (
          <div className="event" key={event.id}>
            <Link className="name" to={`/events/${event.id}`}>
              {event.name}
            </Link>
            <div className="dates">{formatEventDates(event)}</div>
          </div>
        );
      })}
    </div>
  );
};

const DisplayArbiter = ({ loading, error, children }) => {
  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>{error}</div>;
  }

  return children;
};

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [events, setEvents] = useState({
    items: [],
    total: 0,
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    setLoading(true);
    api
      .get("/events/", {
        params: {
          limit: events.limit,
          offset: events.offset,
        },
      })
      .then(({ data }) => {
        setEvents(data);
      })
      .catch((error) => {
        const status = error.response?.status || 500;
        setError(`Error: ${status}`);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <EventList>
      <div className="top">
        <header>
          <h1>Events</h1>
          <p>{events.total} events</p>
        </header>

        <Link to="/events/new" className="new">
          New Event
        </Link>
      </div>
      <DisplayArbiter loading={loading} error={error}>
        <EventsTable events={events} />
      </DisplayArbiter>
    </EventList>
  );
};

const EventList = styled.div`
  width: 100%;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    // background: #333;
    // color: white;

    header {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      h1 {
        margin: 0;
        font-size: 1.5rem;
      }

      p {
        margin: 0 0 0 1rem;
        font-size: 0.8rem;
      }
    }
    .new {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      color: #333;
      border: none;
      cursor: pointer;
      border: solid 1px #ccc;
      text-decoration: none;

      &:hover {
        background: #f0f0f0;
      }
    }
  }

  .events {
    display: flex;
    flex-direction: column;
    // padding: 1rem;
    border-top: 1px solid #333;
    // margin: 1rem;

    .event {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      margin: 0;
      padding: 0.5rem 1rem;
      // padding: 0.5rem;
      // border-bottom: 1px solid #ccc;
      background: #f0f0f0;

      &:nth-child(odd) {
        background: #f9f9f9;
      }

      .name {
        font-size: 1rem;
        // text-decoration: none;
        color: #333;
      }

      .dates {
        font-size: 0.8rem;
        color: #666;
      }

      &.header {
        // background: #333;
        font-weight: bold;

        .name,
        .dates {
          font-size: 0.7rem;
          color: #666;
        }
      }
    }
  }
`;

export default Events;
