import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";

import { api } from "../api";
import { partiesState, partyEditorState } from "../atoms";
import { useErrorHandler, useModalManager } from "../utils";
import AirportSelector from "./airport-selector";
import DoubleTapButton from "./double-tap-button";
import { LocationInput } from "./location-input";
import { GenericFormModal } from "./modal";

const PartyEditor = () => {
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams();
  const { errors, handleApiError } = useErrorHandler();
  const setParties = useSetAtom(partiesState);
  const [party, setParty] = useAtom(partyEditorState);
  const [, setModal] = useModalManager();

  const change = useCallback(
    (e) => {
      setParty((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [setParty]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      let url = `/events/${eventId}/parties`;
      if (party.id) {
        url += `/${party.id}`;
      }

      const fn = party.id ? api.patch : api.put;

      fn(url, Object.fromEntries(new FormData(e.target).entries()))
        .then(({ data }) => {
          setParties((prev) => ({
            ...prev,
            items: party.id
              ? prev.items.map((item) => (item.id === data.id ? data : item))
              : [...prev.items, data],
          }));
          setModal("partyEditor", false);
          setLoading(false);
          setParty(defaultParty);
        })
        .catch(handleApiError)
        .finally(() => setLoading(false));
    },
    [party.id]
  );

  const deleteButton = useMemo(() => {
    if (!party.id) return null;

    const onClick = () => {
      api
        .delete(`/events/${eventId}/parties/${party.id}`)
        .then(() => {
          setParties((prev) => ({
            ...prev,
            items: prev.items.filter((item) => item.id !== party.id),
          }));
          setModal("partyEditor", false);
          setParty(defaultParty);
        })
        .catch(handleApiError);
    };

    return (
      <DoubleTapButton className="delete" onClick={onClick}>
        Delete
      </DoubleTapButton>
    );
  }, [party.id]);
  console.log(party);
  return (
    <GenericFormModal
      name="partyEditor"
      onSubmit={onSubmit}
      loading={loading}
      size="md"
      title={party.id ? party.name : "New Party"}
      submitText={party.id ? "Save" : "Create"}
      leftButton={deleteButton}
    >
      <div className="fields">
        <div className="field">
          <label>Name</label>
          <input type="text" name="name" value={party.name} onChange={change} />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="field">
          <label>Location</label>
          <LocationInput atom={partyEditorState} />
          {errors.location && <span className="error">{errors.location}</span>}
          {errors.point && <span className="error">{errors.point}</span>}
        </div>

        <div className="field">
          <label>Airport</label>
          <AirportSelector item={party} onChange={change} />
          {errors.airport && <span className="error">{errors.airport}</span>}
          {!party.point && (
            <span className="note">
              No location selected, so no airports are available
            </span>
          )}
        </div>

        <div className="field">
          <label>Max Distance (km)</label>
          <input
            type="int"
            name="max_distance"
            value={party.max_distance}
            onChange={change}
          />
          {errors.max_distance && (
            <span className="error">{errors.max_distance}</span>
          )}
        </div>

        <div className="field">
          <label>Max Hops</label>
          <input
            type="int"
            name="max_hops"
            value={party.max_hops}
            onChange={change}
          />
          {errors.max_hops && (
            <span className="error">{errors.max_hops}</span>
          )}
        </div>

        <div className="field">
          <label>Color</label>
          <input
            type="color"
            name="color"
            value={party.color}
            onChange={change}
          />
          {errors.color && <span className="error">{errors.color}</span>}
        </div>
      </div>
    </GenericFormModal>
  );
};

export default PartyEditor;
