import axios from "axios";
import { getDefaultStore } from "jotai";
import { defaultTokens, tokensState } from "./atoms";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.duckbo.at"
    : "http://localhost:8000";

export const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    post: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});

// has no interceptor defined below
const nonAuthAPI = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    post: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  },
});

const store = getDefaultStore();

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(
    decodeURIComponent(
      Buffer.from(base64, "base64")
        .toString("binary")
        .split("")
        .map((c) => `%${c.charCodeAt(0).toString(16).padStart(2, "0")}`)
        .join("")
    )
  );
}

function validToken(token) {
  if (!token) return false;
  const parsed = parseJwt(token);
  return Date.now() < parsed?.exp * 1000;
}

api.interceptors.request.use(
  async (config) => {
    const { access, refresh } = store.get(tokensState);

    if (validToken(access)) {
      config.headers.Authorization = `Bearer ${access}`;
    } else if (validToken(refresh)) {
      try {
        const { data: newTokens } = await nonAuthAPI.post("/auth/refresh", {
          access,
          refresh,
        });

        store.set(tokensState, newTokens);
        config.headers.Authorization = `Bearer ${newTokens.access}`;
      } catch (error) {
        if (error?.code === "ERR_NETWORK") {
          console.error("Network error while refreshing token:", error);
        } else {
          console.error("Failed to refresh token:", error);
          store.set(tokensState, defaultTokens);
        }
      }
    } else {
      store.set(tokensState, defaultTokens);
    }

    return config;
  },
  (error) => Promise.reject(error)
);
