import classnames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useAtom, useSetAtom } from "jotai";
import { api } from "./api";
import {
  defaultMe,
  defaultTokens,
  meState,
  modalsState,
  tokensState,
} from "./atoms";

// import io from "socket.io-client";
// export const socket = io("nowhere", { port: 5000, rememberTransport: false });

export const useEscape = (callback) => {
  useEffect(() => {
    const esc = (e) => e.key === "Escape" && callback();
    document.addEventListener("keydown", esc);
    return () => document.removeEventListener("keydown", esc);
  }, []);
};

export const withUserLoader = () => {
  const [me, setMe] = useAtom(meState);
  const setTokens = useSetAtom(tokensState);

  const loadUser = () => {
    api
      .get("/auth/me")
      .then(({ data }) => {
        setMe(data);
      })
      .catch((error) => {
        console.error("Failed to fetch user data:", error);
        setMe(defaultMe);
        setTokens(defaultTokens);
      });
  };

  return [me, setMe, loadUser];
};

export const useModalManager = () => {
  const [modals, setModals] = useAtom(modalsState);

  const showModal = useCallback(
    (modalKey, value = true) => {
      setModals((prevModals) =>
        Object.keys(prevModals).reduce((newModals, key) => {
          newModals[key] = key === modalKey && value;
          return newModals;
        }, {})
      );
    },
    [setModals]
  );

  return [modals, showModal];
};

export const paginatedState = {
  items: [],
  total: 0,
  per_page: 0,
  page: 0,
};

const statusResponses = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  429: "Too Many Requests",
  500: "Internal Server Error",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
};

// can useSetTitle("new title") or useSetTitle((oldTitle) => `${oldTitle} - new title`)
// you can also
// const setTitle = useSetTitle();
// and then setTitle("new title") or setTitle((oldTitle) => `${oldTitle} - new title`)
// as many times as you want and the title will reset on unmount
export const useSetTitle = (initialUpdate) => {
  const originalTitle = useRef(document.title);

  const updateTitle = useCallback((newTitle) => {
    document.title =
      typeof newTitle === "function"
        ? newTitle(originalTitle.current)
        : newTitle;
  }, []);

  useEffect(() => {
    if (initialUpdate) updateTitle(initialUpdate);
    // when the component unmounts, reset the title
    return () => (document.title = originalTitle.current);
  }, [initialUpdate, updateTitle]);

  return updateTitle;
};

// pulls the pydantic errors out of the response
// and maintains the error state
export const useErrorHandler = (defaultFieldName = "global") => {
  const [errors, setErrors] = useState({});

  const handleApiError = (error) => {
    const { data, status } = error?.response || {};

    let newErrors = {};
    if (status === 422 && Array.isArray(data?.detail)) {
      data.detail.forEach((errorDetail) => {
        const field = errorDetail.loc ? errorDetail.loc[1] : defaultFieldName;
        newErrors[field] = errorDetail.msg;
      });
    } else {
      newErrors = {
        [defaultFieldName]: data || `Error ${status}: Unknown Error`,
      };
    }

    setErrors(newErrors);
  };

  return { errors, setErrors, handleApiError };
};

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const dayName = (date) => daysOfWeek[date.getDay()];

const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 31;
const YEAR = DAY * 365;

const modifiers = {
  s: SECOND,
  m: MINUTE,
  h: HOUR,
  d: DAY,
  w: WEEK,
  mo: MONTH,
  y: YEAR,
};

const re = /^([0-9]{1,})([a-z]{1,2})$/;

export const formFromEvent = (e) =>
  Object.fromEntries(new FormData(e.target).entries());

// takes a string like "1h" and returns the number of whatever out_units is
// parseSpan("1h", "s") == 3600
// parseSpan("1h", "m") == 60
// parseSpan("1xz", "m") == 0
const parseSpan = (span, out_units = "m") => {
  const match = span.match(re);
  if (match) {
    const [, val, marker] = match;
    const in_modifier = modifiers?.[marker];
    const out_modifier = modifiers?.[out_units];
    if (in_modifier && out_modifier) {
      return Math.round((parseInt(val) * in_modifier) / out_modifier);
    }
  }
  return 0;
};

export const timespans = [
  "15m",
  "1h",
  "6h",
  "12h",
  "1d",
  "2d",
  "1w",
  "2w",
  "1mo",
  "6mo",
  "1y",
].reduce((acc, value) => {
  acc[value] = `Past ${value}`;
  return acc;
}, {});

// end up with { 0: "Off", 3: "3s", 5: "5s", ... 60: "1m" }
export const updateOptions = ["Off", "3s", "5s", "10s", "30s", "1m"].reduce(
  (acc, value) => {
    acc[parseSpan(value.toLowerCase(), "s")] = value;
    return acc;
  },
  {}
);

export const orders = {
  recent: "Recently Mentioned",
  score: "Scored",
};

export const Dropdown = ({
  options,
  onSelect,
  className,
  defaultOption = "",
  placeholder = "Select...",
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (defaultOption && !selected) {
      setSelected(defaultOption);
    }
  }, []);

  const handleSelected = (event) => {
    const value = event.target.getAttribute("value");
    setSelected(value);
    onSelect(value);
    setOpen(false);
  };

  let timeout;
  const handleMouseEnter = () => clearInterval(timeout);
  const shut = () => setOpen(false);
  const handleMouseLeave = () => {
    timeout = setTimeout(shut, 1200);
  };

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <StyledDropdown
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="selection" onClick={toggleOpen}>
        {options?.[selected] || placeholder}
      </div>
      <div
        className={classnames({
          options: true,
          open,
        })}
      >
        {Object.entries(options).map(([k, value]) => (
          <div className="option" onClick={handleSelected} value={k} key={k}>
            {value}
          </div>
        ))}
      </div>
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  position: relative;
  max-width: 150px;
  width: 100%;

  border: solid 1px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  min-width: 110px;

  .selection {
    padding: 3px;
  }

  .options {
    transition: margin-top 0.1s, opacity 0.1s;

    position: absolute;
    // display: none;
    opacity: 0;
    pointer-events: none;
    display: block;
    width: calc(100% - 2px);

    border: solid 1px rgba(0, 0, 0, 0.4);
    border-top-width: 0;
    margin-top: -3px;

    &.open {
      pointer-events: auto;
      opacity: 1;
      margin-top: 1px;
    }

    .option {
      padding: 3px;
      background: #fff;

      &:hover {
        background: #ccc;
      }
    }
  }
`;

export const Working = () => {
  return (
    <CoolShit>
      <div className="lines" />
    </CoolShit>
  );
};

export const CoolShit = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .lines {
    display: inline-block;
    width: 20px;
    height: 20px;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid rgba(0, 0, 0, 0.4);
      border-color: rgba(0, 0, 0, 0.4) transparent rgba(0, 0, 0, 0.4)
        transparent;
      animation: shiiiiit 1.2s linear infinite;
    }
  }

  @keyframes shiiiiit {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
