import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  createContext,
  useContext,
} from "react";
import styled from "styled-components";
import { useParams, Link, useNavigate } from "react-router";
import { FaPencilAlt } from "react-icons/fa";

import {
  eventState,
  partiesState,
  scenariosState,
  routesState,
  filterState,
  mapState,
  loadingState,
  airlinesState,
  airportsState,
} from "../atoms.js";
import classNames from "classnames";


import Controls from "../workbench/Controls.js";
import Map from "../workbench/Map.js";

import { DateTime } from "luxon";
import { useErrorHandler } from "../utils.js";
import { api } from "../api.js";

const NewEvent = () => {
  const { errors, handleApiError } = useErrorHandler();
  const nav = useNavigate();

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());

    data.start = DateTime.fromISO(data.start).toUTC().toISO();
    data.end = DateTime.fromISO(data.end).toUTC().toISO();

    api
      .post("/events/", data)
      .then(({ data }) => {
        nav(`/events/${data.id}`);
      })
      .catch(handleApiError);
  }, []);

  return (
    <EventEditor>
      <div className="top">
        <header>
          <h1>New Event</h1>
        </header>
      </div>
      <form onSubmit={onSubmit}>
        <div className="input">
          <label>Name</label>
          <input type="text" name="name" />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="input">
          <label>Description</label>
          <textarea name="description" />
          {errors.description && (
            <span className="error">{errors.description}</span>
          )}
        </div>

        <div className="input">
          <label>Dates</label>
          <p>A broad range is best to start with</p>
          <div className="dates">
            <input type="date" name="start" />
            <span>to</span>
            <input type="date" name="end" />
          </div>
          {errors.start && <span className="error">{errors.start}</span>}
          {errors.end && <span className="error">{errors.end}</span>}
          {errors.dates && <span className="error">{errors.dates}</span>}
        </div>

        <div className="controls">
          <Link to="/events">Cancel</Link>
          <button type="submit">Save</button>
        </div>
      </form>
    </EventEditor>
  );
};

const EventEditor = styled.div`
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    // background: #333;
    // color: white;

    header {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      h1 {
        margin: 0;
        font-size: 1.5rem;
      }
    }
  }

  form {
    padding: 1rem;

    .input {
      display: flex;
      flex-direction: column;
      margin: 1rem 0;

      label {
        margin: 0 0 0.5rem;
      }

      p {
        font-size: 0.7rem;
        margin: 0 0 0.1rem;
        color: #666;
      }

      input,
      textarea {
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
      }

      .dates {
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
        }

        span {
          margin: 0 1rem;
        }
      }

      .error {
        color: red;
        font-size: 12px;
        margin-top: 0.5rem;
      }
    }

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;

      button {
        padding: 0.5rem 1rem;
        background: #333;
        color: white;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
        margin-left: 10px;
      }

      a {
        padding: 0.5rem 1rem;
        background: #ccc;
        color: white;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
        text-decoration: none;
        margin-left: auto;
      }
    }
  }
`;

export default NewEvent;
