import { AiOutlineLoading } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = () => (
  <StyledLoader>
    <SpinningIcon />
  </StyledLoader>
);

const SpinningIcon = styled(AiOutlineLoading)`
  font-size: 1rem;
  animation: ${spin} 0.5s linear infinite;
`;

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 2rem;
  padding: 1rem;
  width: 100%;
  margin: 0;

  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  background: white;
  border: solid 1px #ccc;
  border-radius: 0 0 4px 4px;
  border-top: none;
`;
