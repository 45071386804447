import React, { useCallback, useState } from "react";
import { useAtom } from "jotai";
import styled from "styled-components";
import classnames from "classnames";
import { FaCheck } from "react-icons/fa";

import { api } from "../api";
import { debounce } from "../utils";
import { Loading } from "../components/loaders";

export const LocationInput = ({ atom }) => {
  // atom must support location:str and coordinates:dict
  const [{ location, point }, setState] = useAtom(atom);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const fetchLocations = useCallback(
    debounce((query) => {
      if (query.length < 3) return;

      setLoading(true);
      api
        .get(`/location/autocomplete?query=${query}`)
        .then(({ data }) => {
          setLocations(data?.addresses || []);
        })
        .catch(() => setLocations([]))
        .finally(() => setLoading(false));
    }, 500),
    []
  );

  const onInputChange = (e) => {
    setState((state) => ({
      ...state,
      location: e.target.value,
      // coordinates: 
      point: "",
    }));
    fetchLocations(e.target.value);
  };

  let timeout;
  const close = useCallback(() => setOpen(false), []);
  const onFocus = () => {
    clearTimeout(timeout);
    setOpen(true);
  };
  const onBlur = () => {
    timeout = setTimeout(close, 200);
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { formattedAddress, geometry } =
      locations?.[parseInt(e.target.dataset.idx)];

    console.log(geometry)

    setState((state) => ({
      ...state,
      location: formattedAddress,
      point: geometry.coordinates.join(","),
      // coordinates: geometry.coordinates,
    }));

    close();
    setLocations([]);
  };

  let content = null;

  if (open) {
    if (loading) {
      content = <Loading />;
    } else if (locations.length > 0) {
      content = (
        <ul>
          {locations.map((location, i) => (
            <li key={i} data-idx={i} onClick={onClick}>
              {location.formattedAddress}
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <StyledLocationInput>
      <input
        className={classnames({
          "location-input": true,
          open: open && (locations.length > 0 || loading),
        })}
        type="text"
        name="location"
        value={location}
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {point && <FaCheck className="check" />}
      <input type="hidden" name="point" value={point} />
      {content}
    </StyledLocationInput>
  );
};

const StyledLocationInput = styled.div`
  position: relative;

  .location-input {
    border-radius: 4px;
    position: relative;
    z-index: 11;
    outline: none;

    &.open {
      border-radius: 4px 4px 0 0 !important;
    }
  }

  svg.check {
    position: absolute;
    right: 10px;
    font-size: 10px;
    color: green;
    height: 14px;
    top: calc(1rem - 7px);
    z-index: 12;
  }

  ul {
    z-index: 10;
    position: absolute;
    // 14px for the label
    top: 2rem;
    left: 0;
    right: 0;
    background: white;
    border: solid 1px #ccc;
    border-radius: 0 0 4px 4px;
    border-top: none;
    z-index: 10;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }

  li {
    padding: 0.5rem;
    cursor: pointer;
    background: #fff;
    color: #000;
    font-size: 14px;

    &:hover {
      background: #f0f0f0;
    }
  }
`;
