import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAtom, useSetAtom, useAtomValue } from "jotai";
import { Link, NavLink, Outlet, useNavigate } from "react-router";

import { meState, defaultMe, tokensState, defaultTokens } from "../atoms";
import { useModalManager, withUserLoader } from "../utils";
import LoginModal from "./auth/login";
import RegisterModal from "./auth/register";

export const Shell = () => {
  const [, , loadUser] = withUserLoader();
  const tokens = useAtomValue(tokensState);

  useEffect(() => {
    if (tokens.access) {
      loadUser();
    }
  }, [tokens.access]);

  return (
    <Stage>
      <TopBar />
      <Outlet />
    </Stage>
  );
};
const Stage = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40px auto;
  height: 100vh;
  width: 100vw;
`;

export const Title = ({ children }) => {
  return <StyledTitle>{children}</StyledTitle>;
};
const StyledTitle = styled.div`
  font-size: 24px;
`;

export const TopBar = () => {
  const [me, setMe] = useAtom(meState);
  const setTokens = useSetAtom(tokensState);
  const [modals, showModal] = useModalManager();
  const nav = useNavigate();

  const showLoginModal = () => showModal("login");
  const showRegisterModal = () => showModal("register");

  const logout = () => {
    setMe(defaultMe);
    setTokens(defaultTokens);
    nav("/");
  };

  return (
    <StyledTopBar>
      <div className="logo">
        <Link to="/">midpoint</Link>
      </div>

      <div className="main-menu">
        <Link to="/events">Events</Link>
      </div>

      {me.email && (
        <div className="menu">
          <div className="trigger">
            <div className="avatar">👤</div>
            <div className="name">{me.email}</div>
          </div>
          <div className="actual-menu">
            <Link to="/settings" className="item">
              Settings
            </Link>
            <div className="item" onClick={logout}>
              Logout
            </div>
          </div>
        </div>
      )}

      {!me.email && (
        <div className="buttons">
          <div className="login" onClick={showLoginModal}>
            login
          </div>

          <div className="register" onClick={showRegisterModal}>
            register
          </div>
        </div>
      )}

      {modals.login && <LoginModal />}
      {modals.register && <RegisterModal />}
    </StyledTopBar>
  );
};
const StyledTopBar = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
  z-index: 1000;

  // background: lime;
  height: 40px;

  border-bottom: solid 1px #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    // border-bottom: solid 1px #222;
    color: #222;
    width: 150px;
    background: rgba(0, 0, 0, 0.1);
    letter-spacing: 0.2rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      width: 100%;
      height: 100%;

      color: #222;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .main-menu {
    display: flex;
    gap: 8px;

    a {
      display: flex;
      padding: 0 16px;
      cursor: pointer;
      color: #000;
      font-size: 9px;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        text-decoration: underline;
      }
    }
  }

  .menu {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 150px;
    cursor: pointer;

    .trigger {
      display: flex;
      padding: 4px 8px;
      color: #000;

      height: 40px;
      cursor: default;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 9px;
      align-items: center;
      justify-content: center;
      // border-radius: 3px;
    }

    .actual-menu {
      position: absolute;
      top: 39px;
      right: 0;
      z-index: 1000;
      max-width: 150px;
      width: 100%;
      pointer-events: none;

      margin-top: 2px;
      opacity: 0;
      transition: all 0.2s ease-in-out;

      background: #fff;
      border: solid 1px #ccc;
      border-width: 1px 0 1px 1px;
      border-color: #ccc #ccc #ccc #ccc;

      font-size: 9px;

      .item {
        padding: 8px;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #000;

        &:hover {
          background: #f0f0f0;
        }
      }
    }

    &:hover .actual-menu {
      margin-top: 0px;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    margin: 8px;

    .login,
    .register {
      padding: 8px;
      cursor: pointer;
      background: #222;
      color: #fff;
      display: flex;
      font-size: 9px;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
    }
  }
`;

export const Workbench = ({ children }) => (
  <StyledWorkbench>{children}</StyledWorkbench>
);
const StyledWorkbench = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin: 16px 0 0 16px;
  height: 100%;
  border: solid 1px orange;
`;

export const Content = ({ children, ...rest }) => (
  <StyledContent {...rest}>{children}</StyledContent>
);
const StyledContent = styled.div`
  width: 100%;
  margin-top: 16px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 4px;
      border-bottom: solid 1px #ccc;
    }

    th {
      font-size: 10px;
      color: #666;
      text-align: left;
    }

    td {
      font-size: 12px;
    }
  }

  --color-primary: #3377aa;
`;

export const Skeleton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // dont show this immediately
  // otherwise you get a content flash
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <StyledSkeleton>
      <Content>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </Content>
    </StyledSkeleton>
  );
};

const StyledSkeleton = styled.div`
  @keyframes shimmer {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  .bar {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;

    &:nth-child(2) {
      animation-delay: 0s;
      animation-duration: 0.8s;
    }
    &:nth-child(4) {
      animation-delay: 0.1s;
      animation-duration: 0.9s;
    }
    &:nth-child(1) {
      animation-delay: 0.2s;
      animation-duration: 1s;
    }
    &:nth-child(5) {
      animation-delay: 0.3s;
      animation-duration: 1.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
      animation-duration: 1.2s;
    }
  }

  ${Title} {
    .bar {
      width: 50%;
      height: 28px;
    }
  }

  ${Content} {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .bar {
      height: 15px;
    }
  }
`;
