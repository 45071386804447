import { useAtom } from "jotai";
import React from "react";

import { api } from "../../api";
import { tokensState } from "../../atoms";
import {
  formFromEvent,
  useErrorHandler,
  useModalManager,
  withUserLoader,
} from "../../utils";
import { GenericFormModal } from "../modal";

const RegisterModal = () => {
  const [, , loadUser] = withUserLoader();
  const [, setTokens] = useAtom(tokensState);
  const [, setModal] = useModalManager();
  const { errors, handleApiError } = useErrorHandler();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post("/auth/register", formFromEvent(e))
      .then(({ data }) => {
        setTokens(data);
        loadUser();
        setModal("register", false);
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  return (
    <GenericFormModal
      title="Register"
      name="register"
      onSubmit={onSubmit}
      submitText="Register"
      loading={loading}
      size="sm"
    >
      <div className="fields">
        <div className="field">
          <label>Email</label>
          <input type="email" name="email" />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="field">
          <label>Password</label>
          <input type="password" name="password" />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>
        <div className="field">
          <label>Again please</label>
          <input type="password" name="confirm_password" />
          {errors.confirm_password && (
            <span className="error">{errors.confirm_password}</span>
          )}
        </div>
      </div>
    </GenericFormModal>
  );
};

export default RegisterModal;
