export const getPoint = ({ coordinates: c }) => [c.longitude, c.latitude];

const validPoint = (p) => p.coordinates;

export const getAllPoints = (parties, scenarios) => [
  ...parties.filter(validPoint).map(getPoint),
  ...scenarios.filter(validPoint).map(getPoint),
];

export const getBoundingBox = (coordinates) => {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  coordinates.forEach((coord) => {
    if (coord[0] < minX) minX = coord[0];
    if (coord[1] < minY) minY = coord[1];
    if (coord[0] > maxX) maxX = coord[0];
    if (coord[1] > maxY) maxY = coord[1];
  });

  return [
    [minX, minY],
    [maxX, maxY],
  ];
};
