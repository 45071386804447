import React, { useEffect, useState } from "react";
import { api } from "../api";
import { AIRPORT_DEFAULT } from "../atoms";



const AirportSelector = ({ item, onChange }) => {
  const { airport, point } = item;

  const [choices, setChoices] = useState([airport ? airport : AIRPORT_DEFAULT]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!point) return;
    setLoading(true);

    api
      .get(`/airports/`, { params: { point } })
      .then(({ data }) => {
        setTimeout(() => {
          setChoices(data);
        }, 1000);
      })
      .catch((e) => {
        setChoices([]);
      })
      .finally(() => setLoading(false));
  }, [point]);

  return (
    <select
      name="airport_id"
      defaultValue={airport?.id}
      onChange={onChange}
      // disabled={loading}
    >
      {choices.map((a) => (
        <option key={a.id} value={a.id}>
          {a.IATA} - {a.name}
        </option>
      ))}
    </select>
  );
};

export default AirportSelector;
