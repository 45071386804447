import { useAtom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { FaPencilAlt } from "react-icons/fa";
import { useParams } from "react-router";
import styled from "styled-components";
import { api } from "../api.js";
import { getAllPoints, getBoundingBox } from "../workbench/utils.js";

import classNames from "classnames";
import { atom } from "jotai";
import {
  AIRPORT_DEFAULT,
  defaultScenarioEditor,
  eventState,
  mapState,
  partiesState,
  partyEditorState,
  scenarioEditorState,
  scenariosState,
  defaultGraphState,
  graphState,
  filterState,
} from "../atoms.js";

import PartyEditor from "../components/party-editor.js";
import ScenarioEditor from "../components/scenario-editor.js";

import { GenericFormModal } from "../components/modal.js";
import { useModalManager } from "../utils.js";
import Controls from "../workbench/Controls.js";
import Map from "../workbench/Map.js";

const defaultParty = {
  id: null,
  name: "",
  location: "",
  point: "",
  color: "#000000",
  airport: AIRPORT_DEFAULT,
};

export const useAirportChoices = ({ point }) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    if (!point) return;
    api
      .get(`/airports/`, { params: { point } })
      .then(({ data }) => setChoices(data));
  }, [point]);

  return choices;
};

const Airport = ({ airport }) => (
  <div className="airport">
    <h4>{airport.IATA}</h4>
  </div>
);

const Parties = () => {
  const map = useAtomValue(mapState);
  const [{ selected, items }, setParties] = useAtom(partiesState);
  const [, setModal] = useModalManager();

  const [, setParty] = useAtom(partyEditorState);

  const handleNew = useCallback(() => {
    setModal("partyEditor", true);
    setParty(defaultParty);
  }, []);

  const go = useCallback(
    (id) => {
      map.flyTo({
        center: items.find((party) => party.id === id).point,
        zoom: 3,
        speed: 1.5,
      });
    },
    [map, items]
  );

  const handleClick = useCallback(
    (e) => {
      const find = parseInt(e.target.closest(".party").dataset.id);
      const item = items.find((party) => party.id === find);
      if (!item) return;
      setParties((prev) => ({
        ...prev,
        selected: item.id == selected ? null : item.id,
      }));
      // go(item.id);
    },
    [items, selected]
  );

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      const editing = parseInt(e.target.closest(".party").dataset.id);
      if (editing) {
        setModal("partyEditor", true);

        const {
          id,
          name,
          location,
          coordinates,
          airport,
          color,
          max_distance,
          max_hops,
        } = items.find((party) => party.id === editing);

        let point = "";
        if (coordinates) {
          point = `${coordinates.longitude},${coordinates.latitude}`;
        }

        setParty({
          id,
          name,
          location,
          point,
          airport,
          color,
          max_distance,
          max_hops,
        });
      }
    },
    [items]
  );

  return (
    <div className="parties">
      <header>Parties</header>

      {items.map(({ id, name, airport, color }, i) => (
        <div
          key={i}
          data-id={id}
          className={classNames({
            item: true,
            party: true,
            selected: selected === id,
          })}
          style={{ borderLeftColor: color || "black" }}
          onClick={handleClick}
        >
          <header>
            {name}
            <div className="controls">
              <div className="control edit" onClick={handleEdit}>
                <FaPencilAlt />
              </div>
            </div>
          </header>
          {airport && <Airport airport={airport} />}
        </div>
      ))}

      <div className="add" onClick={handleNew}>
        +
      </div>

      <PartyEditor />
    </div>
  );
};

const Scenarios = () => {
  const map = useAtomValue(mapState);
  const [{ selected, items }, setScenarios] = useAtom(scenariosState);
  const [, setModal] = useModalManager();

  const [, setScenario] = useAtom(scenarioEditorState);

  const handleNew = useCallback(() => {
    setModal("scenarioEditor", true);
    setScenario(defaultScenarioEditor);
  }, []);

  const setSelected = useCallback(
    (id) => setScenarios((prev) => ({ ...prev, selected: id })),
    [map, items]
  );

  const handleClick = useCallback(
    (e) => {
      const find = parseInt(e.target.closest(".scenario").dataset.id);
      const item = items.find((scenario) => scenario.id === find);
      if (!item) return;
      setScenarios((prev) => ({
        ...prev,
        selected: item.id == selected ? null : item.id,
      }));
      // go(item.id);
    },
    [items, selected]
  );

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      const editing = parseInt(e.target.closest(".scenario").dataset.id);
      if (editing) {
        setModal("scenarioEditor", true);

        const selectedItem = items.find((scenario) => scenario.id === editing);

        const { id, name, description, location, coordinates, airport } =
          items.find((scenario) => scenario.id === editing);

        let point = "";
        if (coordinates) {
          point = `${coordinates.longitude},${coordinates.latitude}`;
        }

        setScenario({
          id,
          name,
          description,
          location,
          point,
          airport,
        });
      }
    },
    [items]
  );

  return (
    <div className="scenarios">
      <header>Scenarios</header>

      {items.map(({ id, name, airport }, i) => (
        <div
          key={i}
          data-id={id}
          className={classNames({
            item: true,
            scenario: true,
            selected: selected === id,
          })}
          onClick={handleClick}
        >
          <header>
            {name}
            <div className="controls">
              <div className="control edit" onClick={handleEdit}>
                <FaPencilAlt />
              </div>
            </div>
          </header>
          {airport && <Airport airport={airport} />}
        </div>
      ))}

      <div className="add" onClick={handleNew}>
        +
      </div>

      <ScenarioEditor />
    </div>
  );
};

const MenuModal = () => {
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    console.log(data);
  });

  return (
    <GenericFormModal
      title="Settings"
      name="eventSettings"
      onSubmit={onSubmit}
      submitText="Save"
      loading={loading}
    >
      <div className="fields">
        <div className="field">
          <label>Name</label>
          <input type="text" name="name" />
        </div>
      </div>
    </GenericFormModal>
  );
};

const CityItems = ({ cities }) => {
  if (!cities)
    return (
      <div className="empty">
        <header>No cities</header>
      </div>
    );

  // return cities.map(({ id, name, state, airport }, i) => (
  //   <div key={i} className="item">
  //     <header>{name} <small>{state}</small></header>
  //     {airport && <Airport airport={airport} />}
  //   </div>
  // ));

  return (
    <tbody>
      {cities.map(({ id, name, state, population, airport }, i) => (
        <tr key={i}>
          <td>{name}</td>
          <td>{state}</td>
          <td>{population}</td>
          <td>{airport ? airport.IATA : ""}</td>
        </tr>
      ))}
    </tbody>
  );
};

const Cities = () => {
  const [graphs, setGraphs] = useAtom(graphState);
  const { cities } = graphs;

  return (
    <StyledCities className="cities">
      <header>Cities</header>
      <table className="cities">
        <thead>
          <tr>
            <th>Name</th>
            <th>State</th>
            <th>Population</th>
            <th>Airport</th>
          </tr>
        </thead>
        <CityItems cities={cities} />
      </table>
    </StyledCities>
  );
};

const StyledCities = styled.div`
  .cities {
    width: 100%;

    > header {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .item {
        // padding: 0.5rem;
        // cursor: pointer;
        // transition: background 0.2s;
        // cursor: pointer;
        // opacity: 0.5;

        // border-left: 0.5rem solid black;

        // &.selected,
        // &:hover {
        //   background: #ddd;
        //   opacity: 1;
        // }
      }
    }
  }
`;

const Event = () => {
  const { eventId } = useParams();
  const map = useAtomValue(mapState);
  const [event, setEvent] = useAtom(eventState);
  const [parties, setParties] = useAtom(partiesState);
  const [scenarios, setScenarios] = useAtom(scenariosState);
  const [, setModal] = useModalManager();
  const [filters, setFilters] = useAtom(filterState);

  const [graph, setGraph] = useAtom(graphState);

  useEffect(() => {
    const params = new URLSearchParams({
      noSizeDowngrade: filters.noSizeDowngrade,
    });

    console.log(params.toString());

    api
      .get(`/events/${eventId}/graphs`, { params })
      .then(({ data }) => {
        setGraph(data);
      })
      .catch(console.error);
  }, [parties, scenarios, filters]);

  useEffect(() => {
    if (!map) return;

    api
      .get(`/events/${eventId}`)
      .then(({ data }) => {
        const { id, name, parties, scenarios } = data;

        parties.sort((a, b) => a.id > b.id);
        scenarios.sort((a, b) => a.id > b.id);

        setEvent({
          id,
          name,
          editing: false,
        });
        setParties({
          selected: null,
          editing: null,
          items: parties,
        });
        setScenarios({
          selected: null,
          editing: null,
          items: scenarios,
        });

        const points = getAllPoints(parties, scenarios);

        if (points.length) {
          map.fitBounds(getBoundingBox(points), {
            padding: 100,
          });
        } else {
          map.flyTo({
            center: {
              // center of USA
              latitude: 37.8,
              longitude: -96,
            },
            zoom: 3,
          });
        }
      })
      .catch(console.error);
  }, [map]);

  const showMenu = useCallback(() => {
    setModal("menu", true);
  }, []);

  return (
    <EventEditor>
      <div className="top">
        <header>
          <h1>{event.name}</h1>
          {/* <p>some other words</p> */}
        </header>

        <BiMenu className="new" onClick={showMenu} />
      </div>

      <Parties />
      <Scenarios />
      <Map />
      <Controls />
      <Cities />
    </EventEditor>
  );
};

const EventEditor = styled.div`
  background: #eee;
  margin: 0 auto;
  // padding: 0 10px;
  position: relative;
  width: 100%;

  display: grid;
  grid-template-rows: 1fr 6fr auto;
  grid-template-columns: 1fr 1fr 8fr 1fr;
  gap: 0.5rem;

  .top {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    // background: #333;
    // color: white;
    border-bottom: 1px solid #333;

    header {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      h1 {
        margin: 0;
        font-size: 1.5rem;
      }

      p {
        margin: 0 0 0 1rem;
        font-size: 0.8rem;
      }
    }

    .new {
      color: #000;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      // border-radius: 4px;
      // font-size: 12px;
      // color: #333;
      // border: none;
      cursor: pointer;
      // border: solid 1px #ccc;
      // text-decoration: none;

      // &:hover {
      //   background: #f0f0f0;
      // }
    }
  }

  .parties {
    grid-column: 1;
    grid-row: 2;

    > header {
      border-bottom-color: yellow;
    }

    .party {
      &.selected,
      &:hover {
        border-left-color: yellow;
      }
    }
  }

  .scenarios {
    grid-column: 2;
    grid-row: 2;

    > header {
      border-bottom-color: red;
    }

    .scenario {
      &.selected,
      &:hover {
        border-left-color: red;
      }
    }
  }

  .cities {
    grid-column: 1 / -1;
    grid-row: 3;
  }

  > .menu {
    grid-row: 2;

    width: 100%;
    max-width: 300px;

    &,
    form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .name {
        font-size: 1.2rem;
        font-weight: bold;
        // text-align: center;
      }
    }

    background: rgba(255, 0, 0, 0.5);

    // whatever gap is
    margin-left: -0.5rem;
    padding: 10px;

    position: absolute;

    background: #fff;
    border: solid 1px black;

    label {
      font-size: 0.8rem;
    }

    &.party {
      z-index: 10;
      grid-column: 2 / span 2;
    }

    &.scenario {
      z-index: 5;
      grid-column: 3;
      // background: red;
    }

    input,
    select {
      width: 100%;
    }

    .controls {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: space-between;

      fieldset {
        border: none;
        padding: 0;
        margin: 0;

        legend {
          font-size: 0.8rem;
          font-weight: bold;
        }

        label {
          font-size: 0.8rem;
        }

        input {
          width: 100%;
        }

        .controls {
          display: flex;
          gap: 10px;
          flex-direction: row;
          justify-content: space-around;
        }
      }

      button:first-child {
        margin-left: auto;
      }
    }
  }

  .item {
    padding: 0.5rem;
    cursor: pointer;
    transition: background 0.2s;
    cursor: pointer;
    opacity: 0.5;

    border-left: 0.5rem solid black;

    &.selected,
    &:hover {
      background: #ddd;
      opacity: 1;
    }

    > header {
      margin: 0;
      font-size: 16px;
    }
  }

  .parties,
  .scenarios,
  > .controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > header {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .add {
      text-align: center;
      padding: 0.3rem;
      cursor: pointer;
      box-shadow: 0rem 0rem 0rem rgba(0, 0, 0, 0);
      transition: box-shadow 0.2s;
      color: white;

      &:hover {
        box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.5);
      }
    }
  }

  .map {
    grid-column: 3;
    grid-row: 2;
  }

  .marker {
    border: solid 1px transparent;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;

    &.scenario {
      opacity: 0.3;
      background-color: red;
    }

    &.selected {
      border-color: black;
      opacity: 1;
    }

    &.potential-city {
      background: white;
    }
  }

  > .controls {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;

    .filters {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .filter {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        small {
          display: none;
          font-size: 0.7rem;
          margin: 0.5rem 0;
        }
      }
    }
  }
`;

export default Event;
