import styled from "styled-components";
import { IoCloseSharp } from "react-icons/io5";

import { useEscape, useModalManager } from "../utils";
import { Button } from "./inputs";


export const GenericFormModal = ({
  children,
  title,
  name,
  size="md",
  loading = false,
  onSubmit = () => {},
  submitText = "Submit",
  onClose = () => {},
  closeText = "Close",
  leftButton = null,
}) => {
  const [{ [name]: open }, setModal] = useModalManager();

  const handleClose = () => {
    onClose();
    setModal(name, false);
  };
  useEscape(handleClose);

  if (!open) return null;

  // const doNothing = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  return (
    <ModalStyle
      // onClick={handleClose}
      className={size}
      // onMouseUp={doNothing}
    >
      <div
        className="container"
        // onClick={doNothing}
        // onMouseDown={doNothing}
      >
        <header>
          <h3>{title}</h3>
          <div className="controls">
            <div className="control close" onClick={handleClose}>
              <IoCloseSharp />
            </div>
          </div>
        </header>
        <form className="content" onSubmit={onSubmit}>
          {children}

          <div className="actions">
            <div className="left">
              {leftButton}
            </div>
            <div className="right">
              <Button type="button" onClick={handleClose}>
                {closeText}
              </Button>
              <Button type="submit" disabled={loading}>
                {submitText}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </ModalStyle>
  );
};

const ModalStyle = styled.div`
  // top level element is the shade
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.75);
  padding: 1rem;
  border: solid 1px black;
  z-index: 1000;

  &.sm .container {
    max-width: 300px;
  }

  &.md .container {
    max-width: 600px;
  }

  .container {
    padding: 1rem;
    margin: 0 auto;
  }

  // @media (min-width: 600px) {
  //   .container {
  //     padding: 1rem 4rem;
  //   }
  // }

  // @media (min-width: 800px) {
  //   .container {
  //     padding: 1rem 8rem;
  //   }
  // }

  // @media (min-width: 1000px) {
  //   .container {
  //     padding: 1rem 12rem;
  //   }
  // }

  // @media (min-width: 1200px) {
  //   .container {
  //     padding: 1rem 16rem;
  //   }
  // }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0 0 0 4px;
      color: white;
      font-size: 1.5rem;
    }

    .controls {
      display: flex;
      gap: 10px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      color: white;

      .close {
        height: 1.3rem;
        width: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 4px;

    .fields {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .field {
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
      }

      input, select {
        padding: 4px;
        border-radius: 4px;
        border: solid 1px #ccc;
        height: 2rem;
        font-size: 14px;
        width: 100%;
      }

      .error {
        color: #ff3311;
        font-size: 11px;
        margin-top: 2px;
      }

      .note {
        font-size: 11px;
        color: #666;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding-top: 10px;

      .left, .right {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
`;
