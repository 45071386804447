import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";

import { api } from "../api";
import { scenarioEditorState, scenariosState } from "../atoms";
import { useErrorHandler, useModalManager } from "../utils";
import AirportSelector from "./airport-selector";
import DoubleTapButton from "./double-tap-button";
import { LocationInput } from "./location-input";
import { GenericFormModal } from "./modal";
import { Button } from "./inputs";

const ScenarioEditor = () => {
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams();
  const { errors, handleApiError } = useErrorHandler();
  const setScenarios = useSetAtom(scenariosState);
  const [scenario, setScenario] = useAtom(scenarioEditorState);
  const [, setModal] = useModalManager();

  const change = useCallback(
    (e) => {
      setScenario((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [setScenario]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const data = Object.fromEntries(new FormData(e.target).entries());
      let url = `/events/${eventId}/scenarios`;
      if (scenario.id) {
        url += `/${scenario.id}`;
      }

      const fn = scenario.id ? api.patch : api.put;

      fn(url, data)
        .then(({ data }) => {
          setScenarios((prev) => ({
            ...prev,
            items: scenario.id
              ? prev.items.map((item) => (item.id === data.id ? data : item))
              : [...prev.items, data],
          }));
          setModal("scenarioEditor", false);
          setLoading(false);
          setScenario(scenarioEditorState);
        })
        .catch(handleApiError);
    },
    [eventId, scenario, setScenarios, setScenario]
  );

  const deleteButton = useMemo(() => {
    if (!scenario.id) return null;

    const onClick = () => {
      setLoading(true);
      api
        .delete(`/events/${eventId}/scenarios/${scenario.id}`)
        .then(() => {
          setScenarios((prev) => ({
            ...prev,
            items: prev.items.filter((item) => item.id !== scenario.id),
          }));
          setModal("scenarioEditor", false);
        })
        .catch(handleApiError)
        .finally(() => setLoading(false));
    };

    return (
      <DoubleTapButton className="delete" onClick={onClick}>
        Delete
      </DoubleTapButton>
    );
  }, [eventId, scenario.id, setScenarios]);

  return (
    <GenericFormModal
      name="scenarioEditor"
      onSubmit={onSubmit}
      loading={loading}
      size="md"
      title={scenario.id ? scenario.name : "New Scenario"}
      submitText={scenario.id ? "Save" : "Create"}
      leftButton={deleteButton}
    >
      <div className="fields">
        <div className="field">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={scenario.name}
            onChange={change}
          />
        </div>

        <div className="field">
          <label>Location</label>
          <LocationInput atom={scenarioEditorState} />
          {errors.location && <span className="error">{errors.location}</span>}
          {errors.point && <span className="error">{errors.point}</span>}
        </div>

        <div className="field">
          <label>Airport</label>
          <AirportSelector item={scenario} onChange={change} />
          {errors.airport && <span className="error">{errors.airport}</span>}
          {!scenario.point && (
            <span className="note">
              No location selected, so no airports are available
            </span>
          )}
        </div>

        <div className="field">
          <label>Color</label>
          <input
            type="color"
            name="color"
            value={scenario.color}
            onChange={change}
          />
          {errors.color && <span className="error">{errors.color}</span>}
        </div>
      </div>
    </GenericFormModal>
  );
};

export default ScenarioEditor;
