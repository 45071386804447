import styled from 'styled-components';
import { Link } from 'react-router';

export const ButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #333;
  border: none;
  cursor: pointer;
  border: solid 1px #ccc;
  text-decoration: none;
  background: #f9f9f9;

  &:hover {
    background: #f0f0f0;
  }

  &.delete {
    background: #ffaaaa;
    color: #f0f0f0;

    &:hover {
      background: #ff6666;
    }
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const ButtonLink = styled(Link)`
  ${ButtonStyles}
`;
