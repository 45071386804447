import { useAtom } from "jotai";
import React, { useState } from "react";

import { api } from "../../api";
import { tokensState } from "../../atoms";
import {
  useErrorHandler,
  useModalManager,
  withUserLoader,
  formFromEvent,
} from "../../utils";
import { GenericFormModal } from "../modal";

const LoginModal = () => {
  const [, , loadUser] = withUserLoader();
  const [, setTokens] = useAtom(tokensState);
  const [, setModal] = useModalManager();
  const { errors, handleApiError } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post("/auth/login", formFromEvent(e))
      .then(({ data }) => {
        setTokens(data);
        loadUser();
        setModal("login", false);
      })
      .catch(handleApiError)
      .finally(() => setLoading(false));
  };

  return (
    <GenericFormModal
      title="Login"
      name="login"
      onSubmit={onSubmit}
      submitText="Login"
      loading={loading}
      size="sm"
    >
      <div className="fields">
        <div className="field">
          <label>Email</label>
          <input type="email" name="email" />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="field">
          <label>Password</label>
          <input type="password" name="password" />
          {errors.password && <span className="error">{errors.password}</span>}
        </div>
      </div>
    </GenericFormModal>
  );
};

export default LoginModal;
